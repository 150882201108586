import "./styles.scss"

import React, { useState } from "react"

const Checkbox = ({
  children,
  name,
  checked,
  onChange,
  required,
  className,
  type,
}) => {
  return (
    <div className={`checkbox${className ? ` ${className}` : ""}`}>
      <label className="checkbox__label">
        <input
          type={type ? type : "checkbox"}
          name={name}
          checked={checked}
          value={checked}
          onChange={onChange}
          required={required}
        />
        <span>
          {required && <sup>*</sup>} {children}
        </span>
      </label>
    </div>
  )
}

export const More = ({ string, offset = 20 }) => {
  const [isShown, setIsShown] = useState(false)

  const array = string.split(" ")
  const isLong = array.length > offset

  const text = isLong ? array.slice(0, offset).join(" ") : string
  const more = isLong ? array.slice(offset, array.length).join(" ") : undefined

  return (
    <span className="checkbox-more">
      {text}
      {more &&
        (!isShown ? (
          <>
            ...
            <button type="button" onClick={() => setIsShown(true)}>
              więcej
            </button>
          </>
        ) : (
          <> {more}</>
        ))}
    </span>
  )
}

export default Checkbox
